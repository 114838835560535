import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { parentPageVariants } from '../../utils/constants';
import * as ChildrenViews from './index';

const Appointments = () => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={parentPageVariants}
            transition={{ ease: [0.5, 1, 0.89, 1], duration: 1 }}>
            <Outlet />
        </motion.div>
    );
};

export default Object.assign(Appointments, { ...ChildrenViews });
