import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/auth';
import adminReducer from '../features/admin/adminSlice';
import assessmentReducer from '../features/assessment/assessmentSlice';
import authReducer from '../features/auth/authSlice';
import cartReducer from '../features/cart/cartSlice';
import catalogReducer from '../features/catalog/catalogSlice';
import ordersReducer from '../features/orders/ordersSlice';
import uiReducer from '../features/ui/uiSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        admin: adminReducer,
        assessment: assessmentReducer,
        auth: authReducer,
        cart: cartReducer,
        catalog: catalogReducer,
        orders: ordersReducer,
        ui: uiReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});
