function InsuranceDisclaimer({ extraStyling }) {
    return (
        <p className={extraStyling}>
            *Insurance coverage for supplies will vary, to qualify you may be required to use your
            machine a certain amount and/or complete a compliance visit.
        </p>
    );
}

export default InsuranceDisclaimer;
