import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatSquarePrice } from '../../utils/constants';
import { toggleInsurance } from '../../features/cart/cartSlice';
import { CartProduct } from './CartProduct';
import DiscountForm from './DiscountForm';
import { useInsuranceContext } from '../Insurance';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useGetCartQuery } from '../../app/services/cart';

//images
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as InsuranceGreenExclamation } from '../../images/icons/insurance-green-exclamation.svg';

const CartSlider = ({ closeHandler, insuranceQuestionHandler }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orderItems, usingInsurance, subtotal, cpapInCart } = useSelector((state) => state.cart);
    const { hasInsuranceAlternative, insurance } = useInsuranceContext();
    const { checkoutStep, finishInsuranceCheck, setCheckoutStep } = useCheckoutContext();

    const { loading: cartLoading } = useGetCartQuery();

    const numberOfCPAPInCart = orderItems.filter(
        (item) => item.itemCategory === 'CPAP_SUPPLIES',
    ).length;
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setErrorMessage(null);
        //Make sure the user only has a single CPAP in cart
        if (numberOfCPAPInCart > 1) {
            setErrorMessage('You can only checkout with a single CPAP');
        }
    }, [numberOfCPAPInCart]);

    const checkoutClickHandler = async (ev) => {
        if (cpapInCart && usingInsurance !== undefined) {
            //Ask users with CPAP in cart that isn't covered if they want to add insurance
            if (!insurance?.payer?.CPAPCovered || !insurance) {
                insuranceQuestionHandler();
                //Route CPAP checkout when using insurance and cpapInCart to insurance review
            } else if (insurance?.payer?.CPAPCovered && usingInsurance) {
                closeHandler();
                if (hasInsuranceAlternative) {
                    setCheckoutStep('insurance-alternative-check');
                } else {
                    setCheckoutStep('insurance-review');
                }
                navigate('/dashboard/checkout', { replace: true });
            } else {
                //Otherwise route users who declined to use insurance that covers CPAP to address page
                closeHandler();
                finishInsuranceCheck({ routeToRequiredBooking: false });
                navigate('/dashboard/checkout', { replace: true });
            }
        } else {
            //For other orders, route to the checkout process with the typical first checkout step asking about insurance
            closeHandler();
            //Reset checkoutStep if the user has already placed an order since logging in
            if (checkoutStep === 'checkout-success') {
                setCheckoutStep(null);
            }
            navigate('/dashboard/checkout', { replace: true });
        }
    };

    return (
        <div className="relative h-full">
            <nav className="fixed top-[26px] lg:top-[30px] right-5 lg:right-8 z-10 flex space-x-3">
                <button
                    onClick={closeHandler}
                    className="flex items-center font-heading text-sm font-bold">
                    <span className="tracking-widest">close</span>
                    <span className="relative ml-2 aspect-1 w-6 rounded-full border-2 border-green-400">
                        <CloseIcon className="absolute-center" />
                    </span>
                </button>
            </nav>

            <h5 className="relative mt-20 mb-2 xs:mb-6 flex items-center justify-between">
                <span className="font-heading text-4xl font-bold lg:text-5xl">My Order</span>
                {usingInsurance && orderItems.length !== 0 && (
                    <InsuranceGreenExclamation className="ml-auto" />
                )}
            </h5>

            {!usingInsurance && orderItems.length !== 0 && (
                <p className="text-right text-sm xl:text-lg mb-4">
                    Subtotal: <b className="font-bold">{`${formatSquarePrice(subtotal)}`}</b>
                </p>
            )}

            <button
                className={`btn-primary ${errorMessage ? 'mb-4' : 'mb-12'} w-full ${
                    orderItems.length === 0 ? 'pointer-events-none opacity-50' : ''
                }`}
                disabled={cartLoading || errorMessage}
                onClick={checkoutClickHandler}>
                checkout
            </button>

            {errorMessage && (
                <p className="text-red font-heading tracking-wider text-center mb-4">
                    {errorMessage}
                </p>
            )}

            <ul className="mb-8 space-y-2">
                {orderItems.map((product, pIdx) => (
                    <CartProduct key={pIdx} product={product} />
                ))}
            </ul>

            {usingInsurance && orderItems.length !== 0 && (
                <>
                    <p className="mb-4 flex space-x-4 bg-green-200/20 p-4 text-xs text-gem-green lg:text-sm">
                        <InsuranceGreenExclamation className="min-w-[20px]" />
                        <span>
                            <b>All costs pending insurance claim submission.</b> Once your claim is
                            processed, if there is a balance you'll receive a bill via email.
                        </span>
                    </p>
                    <button
                        className="btn-shell mb-12 w-full flex justify-center gap-4"
                        onClick={() => {
                            dispatch(toggleInsurance(false));
                            setCheckoutStep(null);
                        }}>
                        Remove Insurance
                        <CloseIcon />
                    </button>
                </>
            )}

            {orderItems.length !== 0 && !usingInsurance && <DiscountForm />}

            <footer className="lg:text-center h-[80px]">
                <a
                    className="link text-sm"
                    href={`/terms/return-and-cancellation-policy`}
                    target="_blank"
                    rel="noreferrer">
                    Return and Cancellation Policy
                </a>
            </footer>
        </div>
    );
};

export default CartSlider;
