import referralToSleepLabSrc from '../../images/referral-to-sleep-lab.png';
import { AppointmentSchedulingButton } from '../Appointments';

const InPersonClinicNeeded = () => {
    return (
        <div className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4">
            <img
                src={referralToSleepLabSrc}
                alt="Sleep study"
                className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
            />
            <div className="w-full sm:w-3/4 max-w-xl">
                <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                    Visit in person sleep center
                </h5>
                <p className="mb-4 text-[13px] lg:text-base max-w-xl">
                    Based on the results of your sleep study, we recommend that you go to an
                    in-person sleep clinic. Please read your sleep study for more details about your
                    results. You may also schedule time with a GEM Clinician to review your study in
                    more detail.
                </p>
                <p className="mb-2">
                    Need helping finding a clinic?* ➝{' '}
                    <a
                        className="text-gem-purple hover:text-purple-super-dark"
                        href="https://members.aasm.org/AASMMembers/Accreditation/Accredited-Facility-Directory.aspx">
                        Click Here
                    </a>
                </p>
                <p className="text-sm text-gray-600 mb-4">
                    *Insurance coverage will vary by clinic.
                </p>
                <AppointmentSchedulingButton
                    label="Schedule Visit with a GEM Clinician"
                    sku="DIACLINICAL"
                    styling="btn-primary w-full flex justify-between max-w-xl"
                />
            </div>
        </div>
    );
};

export default InPersonClinicNeeded;
