import { motion } from 'framer-motion';
import {
    AfterpayButton,
    AfterpayMessage,
    AfterpayProvider,
    CreditCard,
    PaymentForm,
} from 'react-square-web-payments-sdk';
import { ReactComponent as QualifyBadge } from '../../images/qualify-badge.svg';
import squareLogo from '../../images/squareLogo.png';
import { ErrorBoundary } from '../../utils/constants';
import { useSelector } from 'react-redux';

function Payment({ handleZeroPayment, paymentSubmitHandler, user, paymentProcessing }) {
    const { subtotal, usingInsurance } = useSelector((state) => state.cart);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h2 className="font-heading text-[44px] leading-none lg:mb-3 lg:text-7xl">
                Payment information
            </h2>

            {+subtotal !== 0 && (
                <p className="my-3 sm:mb-10 text-[13px] lg:mb-14 lg:text-base">
                    Enter your card information:
                </p>
            )}

            {+subtotal === 0 ? (
                <div>
                    <p className="my-4 text-[13px] lg:text-base">
                        As part of GEM SLEEP's Friends and Family program, you have received an{' '}
                        <b>exclusive discount code</b>! Your Home Sleep Test is FREE!
                    </p>
                    <p className="mb-4 text-[13px] lg:text-base">
                        Click confirm to complete your order!
                    </p>
                    <button className="btn-primary" onClick={handleZeroPayment}>
                        Confirm
                    </button>
                </div>
            ) : (
                <ErrorBoundary>
                    <div className="border-8 border-purple-medium border-opacity-20 p-8 lg:p-14">
                        <PaymentForm
                            key={subtotal}
                            applicationId={process.env.REACT_APP_SQUARE_APP_ID}
                            locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                            cardTokenizeResponseReceived={(token, buyer) => {
                                paymentSubmitHandler(token.token);
                            }}
                            createPaymentRequest={() => ({
                                shippingContact: {
                                    addressLines: [user.street1, user.street2],
                                    city: user.city,
                                    countryCode: 'US',
                                    email: user.email,
                                    familyName: user.firstName,
                                    givenName: user.lastName,
                                    phone: user.phone,
                                    postalCode: user.zip,
                                    state: user.state,
                                },
                                countryCode: 'US',
                                currencyCode: 'USD',
                                total: {
                                    amount: (subtotal / 100).toString(),
                                    label: 'Total',
                                },
                            })}>
                            <CreditCard
                                buttonProps={{
                                    isLoading: paymentProcessing,
                                }}
                                style={{
                                    input: {
                                        backgroundColor: 'transparent',
                                    },
                                    '.input-container': {
                                        borderRadius: '0',
                                    },
                                }}>
                                Submit
                            </CreditCard>

                            <p className="mt-4 sm:mt-8 flex items-center space-x-2 text-[13px] lg:text-base">
                                <QualifyBadge />
                                <span className="font-bold">
                                    You may use your HSA or FSA to purchase this item.
                                </span>
                            </p>

                            {!usingInsurance && (
                                <>
                                    <div className="my-6 text-center">
                                        <p className="text-bold text-gray-medium relative z-10">
                                            <span className="block absolute border-b-2 w-full top-0 bottom-0 right-0 left-0 m-auto h-0 z-1"></span>
                                            <b className="inline-block bg-offwhite p-2 z-5 relative">
                                                OR
                                            </b>
                                        </p>
                                    </div>

                                    <AfterpayProvider>
                                        <AfterpayButton />
                                        <AfterpayMessage />
                                    </AfterpayProvider>
                                </>
                            )}
                        </PaymentForm>
                    </div>
                </ErrorBoundary>
            )}
            <div className="flex justify-center items-center mt-3">
                <p className="text-[13px] lg:text-base">Secure payments powered by</p>
                <img
                    className="w-[80px] lg:w-[100px]"
                    src={squareLogo}
                    alt="Square Payments Logo"
                />
            </div>
        </motion.div>
    );
}

export default Payment;
