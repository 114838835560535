import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';

import { InsuranceDisclaimer, useInsuranceContext } from '../Insurance';
import { useEnrollInResupplyMutation } from '../../app/services/orders';

function ResupplySignup({ proceedToPayment }) {
    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { usingInsurance } = useSelector((state) => state.cart);

    //Context
    const { insurance } = useInsuranceContext();

    //Queries
    const [enroll] = useEnrollInResupplyMutation();

    //Local State
    const [enrollInResupply, setEnrollInResupply] = useState(true);

    const suppliesCovered = insurance?.payer?.SuppliesCovered && usingInsurance;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="space-y-4">
            <h2 className="font-heading text-[44px] leading-none lg:mb-3 lg:text-5xl">
                Enroll in the GEM Supply Auto-Ship Program?
            </h2>
            <div className="space-y-4 mb-10">
                <p>
                    Make getting new supplies a breeze, we will send you new supplies every 90 days,
                    keeping your machine parts clean and you sleeping your best.
                </p>
                {suppliesCovered && <p>Good news! Your insurance covers supplies*.</p>}

                <p className="font-bold">How it works:</p>

                <ul className="list-disc list-outside pl-4">
                    {suppliesCovered ? (
                        <>
                            <li>
                                Prior to your shipment due date, we will send you an email to
                                confirm your address, insurance, and mask type.
                            </li>
                            <li>Once you approve, items ship directly to your door.</li>
                            <li>
                                After your insurance claim is processed, you will receive an emailed
                                invoice for any remaining balance.
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                Prior to your shipment due date, we will send a confirmation email,
                                including an invoice*.
                            </li>
                            <li>Once we receive payment, your order will ship.</li>
                        </>
                    )}
                </ul>

                <div className="flex items-center space-x-2">
                    <div className="relative">
                        <input
                            type="checkbox"
                            name="Resupply"
                            value={enrollInResupply}
                            checked={enrollInResupply}
                            onChange={() => setEnrollInResupply(!enrollInResupply)}
                            className="float-left h-8 w-8 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-green-600 checked:bg-green-600 focus:outline-none"
                        />
                        <FaCheck className="absolute-center pointer-events-none w-2 scale-[2] text-white opacity-0 transition" />
                    </div>
                    <span className="font-bold">Yes, send me supplies every 90 days.</span>
                </div>

                <p className="text-xs sm:text-sm italic">
                    (we will never send a shipment without your approval)
                </p>

                <button
                    className="btn-primary w-full"
                    onClick={async () => {
                        if (enrollInResupply) {
                            const { data: resupply } = await enroll({
                                id: user?.id,
                            });

                            if (resupply?.enrollmentStatus) {
                                proceedToPayment();
                            } else toast.error('Something went wrong, please try again');
                        } else {
                            proceedToPayment();
                        }
                    }}>
                    Finish Checkout
                </button>

                {suppliesCovered ? (
                    <InsuranceDisclaimer extraStyling="text-sm sm:text-base" />
                ) : (
                    <p className="text-sm sm:text-base">
                        *By enrolling in the auto-ship program, you will receive 20% off your supply
                        orders and free shipping.
                    </p>
                )}
            </div>
        </motion.div>
    );
}

export default ResupplySignup;
