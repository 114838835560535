import { useNavigate, useParams } from 'react-router-dom';

import {
    GeneralOrderInformation,
    OrderItems,
    SelectItemFromCatalog,
    // AddShippingDetails,
    // ShippingDetails,
} from '../../components/Admin/Orders';

import { useAddOrderItemMutation, useGetOrderInfoQuery } from '../../app/services/admin/orders';
import { format } from 'date-fns';
import { colorAppointmentStatus } from '../../utils/constants';
import { adminItemsSelector } from '../../features/catalog/catalogSlice';
import { useSelector } from 'react-redux';

const OrderInformation = () => {
    const navigate = useNavigate();

    //Order information from clicked order
    const { orderId, patientId } = useParams();

    //Expanded order information through fetch
    const { data: order, isFetching: orderFetching } = useGetOrderInfoQuery(
        {
            userId: patientId,
            orderId: orderId,
        },
        { skip: !patientId || !orderId },
    );

    const [addOrderItem] = useAddOrderItemMutation();
    const addItemOptions = useSelector(adminItemsSelector);

    if (!order || orderFetching) return <p className="py-6 font-heading text-5xl">Loading...</p>;
    else
        return (
            <div className="py-6">
                <h2 className="mb-5 flex items-center justify-between">
                    <span className="font-heading text-3xl lg:text-5xl">Order Information</span>
                    <div className="flex flex-row gap-4">
                        <button
                            type="button"
                            onClick={(e) => {
                                navigate(`/admin/patients/${order?.userId}/`);
                            }}
                            className="font-heading text-lg tracking-widest text-gray transition hover:text-white">
                            Go to Patient
                        </button>
                        <button
                            type="button"
                            className="font-heading text-lg tracking-widest text-gray transition hover:text-white"
                            onClick={(ev) => {
                                navigate('/admin/orders');
                            }}>
                            Go to Orders
                        </button>
                    </div>
                </h2>
                <hr className="my-2" />
                <h2 className="ml-2 font-heading text-xl lg:text-3xl">General Information</h2>
                <GeneralOrderInformation order={order} />

                <hr className="my-2" />
                <h2 className="ml-2 font-heading text-xl lg:text-3xl">Order Items</h2>
                <OrderItems
                    key={`order-items.component-${order?.id}`}
                    order={order}
                    patientId={patientId}
                />
                <SelectItemFromCatalog
                    key={`add-order-items.component-${order?.id}`}
                    includeQuantity={true}
                    itemOptions={addItemOptions}
                    label={'Add Item'}
                    successToast="Item Added to Order"
                    onSubmit={(values) =>
                        addOrderItem({
                            orderId: orderId,
                            userId: patientId,
                            values: values,
                        })
                    }
                />
                {/* <hr className="my-2" />
                <h2 className="ml-2 font-heading text-xl lg:text-3xl">Shipping</h2>
                <ShippingDetails order={order} patientId={patientId} />
                <AddShippingDetails
                    key={`add-shipping-details-component`}
                    patientId={patientId}
                    order={order}
                /> */}

                <hr className="my-2" />
                <h2 className="ml-2 font-heading text-xl lg:text-3xl mb-4">Appointments</h2>
                {order.appointments && order.appointments.length > 0 && (
                    <div className="gap-y-2">
                        <div className="border-b border-gray grid grid-cols-[180px_100px_90px_120px_180px_140px] gap-x-2 p-2 ">
                            <p>Type</p>
                            <p>Date</p>
                            <p>Time</p>
                            <p>Status</p>
                            <p>Provider</p>
                            <p>Updated</p>
                        </div>

                        {order.appointments.map((appointment) => (
                            <div
                                key={`${appointment.id}`}
                                className={`grid grid-cols-[180px_100px_90px_120px_180px_140px] gap-x-2 }`}>
                                <p className="whitespace-nowrap overflow-hidden text-ellipsis p-2">
                                    {appointment.appointmentType}
                                </p>
                                <p className="p-2">
                                    {format(new Date(appointment.startAt), 'MM/dd/yyyy')}
                                </p>
                                <p className="p-2">
                                    {format(new Date(appointment.startAt), 'h:mm a')}
                                </p>
                                <p className="p-2">
                                    {colorAppointmentStatus(appointment.appointmentStatus)}
                                </p>
                                <p className="max-w-[18 0px] p-2">
                                    <div
                                        className={`daisy-tooltip daisy-tooltip-right text-left flex items center max-h-[33.8px]`}
                                        data-tip={
                                            appointment.provider.firstName +
                                            ' ' +
                                            appointment.provider.lastName
                                        }>
                                        <p className=" max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                                            {`${appointment.provider.firstName} ${appointment.provider.lastName}`}
                                        </p>
                                    </div>
                                </p>

                                <p className="p-2">
                                    {format(new Date(appointment.updatedAt), 'MM/dd/yyyy')}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
};

export default OrderInformation;
