import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateConsentMutation, useGetPatientConsentsQuery } from '../../app/services/insurance';
import { useGetWebflowTermsQuery } from '../../app/services/webflow';
import { CustomCheckbox } from '../../components/InputComponents';
import { toggleModal, toggle } from '../../features/ui/uiSlice';
import { overlayVariants } from '../../utils/constants';
import { setConsultRequired, toggleInsurance } from '../../features/cart/cartSlice';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useInsuranceContext } from '../Insurance/InsuranceContext';

const CoveredBenefit = () => {
    const dispatch = useDispatch();

    //Redux state Values
    const { agreedToInsuranceBenefits } = useSelector((state) => state.ui);
    const { hstInCart, isConsultOrder, stateRequiredTelehealth } = useSelector(
        (state) => state.cart,
    );

    //Context
    const { setCheckoutStep } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    //Queries
    const { data: consents } = useGetPatientConsentsQuery();
    const { data: webflowTerms } = useGetWebflowTermsQuery();
    const [createConsent] = useCreateConsentMutation();

    const previouslyAgreedToBenefits = consents?.results.find(
        (c) => c.documentType === 'assignment-of-benefit-responsibility',
    );

    const onSubmit = async () => {
        const benefitsConsentData = webflowTerms?.items?.find(
            (t) => t.slug === 'assignment-of-benefit-responsibility',
        );

        if (benefitsConsentData && !previouslyAgreedToBenefits) {
            await createConsent([
                {
                    id: benefitsConsentData._id,
                    version: '' + benefitsConsentData?.version,
                    name: benefitsConsentData.name,
                    documentType: benefitsConsentData.slug,
                },
            ]);
        }

        dispatch(setConsultRequired(stateRequiredTelehealth && hstInCart));
        //Marks cart as using insurance
        dispatch(toggleInsurance(true));
        if (stateRequiredTelehealth && hstInCart) {
            setCheckoutStep('required-consult-booking');
        } else if (isConsultOrder) {
            setCheckoutStep('consult-booking');
        } else {
            setCheckoutStep('address-page');
        }
    };

    return (
        consents && (
            <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
                <h5 className="text-center mb-5 font-heading text-4xl font-bold lg:text-5xl p-8 lg:p-14">
                    Good News! Your services are fully covered by {insurance?.payer?.name}.
                </h5>

                <Formik
                    initialValues={{
                        agreeToBenefits: agreedToInsuranceBenefits,
                    }}
                    enableReinitialize
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="space-y-6 p-8 lg:p-14">
                            <CustomCheckbox
                                theme="light"
                                name="agreeToBenefits"
                                id="agreeToBenefits"
                                className={`${previouslyAgreedToBenefits ? 'hidden' : ''}`}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!agreedToInsuranceBenefits) {
                                        dispatch(toggleModal('assignmentOfBenefits'));
                                    } else {
                                        dispatch(toggle('agreedToInsuranceBenefits'));
                                    }
                                }}
                                label={
                                    <>
                                        I agree to the{' '}
                                        <span className="link break-words hover:cursor-pointer">
                                            Assignment of Benefits and Patient Responsibility
                                        </span>
                                    </>
                                }
                            />

                            <button
                                className="btn-primary w-full"
                                type="submit"
                                disabled={!agreedToInsuranceBenefits || isSubmitting}>
                                Continue
                            </button>
                        </Form>
                    )}
                </Formik>
            </motion.div>
        )
    );
};

export default CoveredBenefit;
