import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { toggleCart } from '../../features/ui/uiSlice';
import { overlayVariants } from '../../utils/constants';
import CartInsuranceQuestion from './CartInsuranceQuestion';
import CartSlider from './CartSlider';
import { useUpdateInsuranceInCartMutation } from '../../app/services/cart';

const Cart = () => {
    const dispatch = useDispatch();

    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { cartOpen } = useSelector((state) => state.ui);
    const { orderType, usingInsurance } = useSelector((state) => state.cart);

    //Local State
    const [cartInsuranceQuestionOpen, setCartInsuranceQuestionOpen] = useState(false);

    //Queries and mutations
    const [updateInsuranceInCart] = useUpdateInsuranceInCartMutation();

    const closeClickHandler = (ev) => {
        dispatch(toggleCart(false));
        setCartInsuranceQuestionOpen(false);
    };

    //Update cart in backend to reflect choice to use/not use insurance
    useEffect(() => {
        if (usingInsurance !== undefined) {
            const cartUsingInsurance = orderType === 'INSURANCE';
            if (cartUsingInsurance !== usingInsurance) {
                updateInsuranceInCart({ usingInsurance: usingInsurance });
            }
        }
    }, [orderType, usingInsurance, updateInsuranceInCart]);

    return (
        <AnimatePresence>
            {cartOpen && (
                <motion.div
                    key="account-overlay"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={overlayVariants}
                    onClick={closeClickHandler}
                    className="fixed top-0 bottom-0 z-30 w-screen bg-purple-dark/90 backdrop-blur-md"></motion.div>
            )}

            <motion.div
                key="cart-content"
                className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-5 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 ${
                    cartOpen ? 'translate-x-0' : 'translate-x-full'
                } flex flex-col`}>
                <CartSlider
                    open={cartOpen}
                    closeHandler={() => {
                        dispatch(toggleCart(false));
                    }}
                    insuranceQuestionHandler={() => {
                        setCartInsuranceQuestionOpen(true);
                    }}
                    userId={user?.id}
                />
            </motion.div>

            <motion.div
                key="cart-insurance-question"
                className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-5 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 ${
                    cartInsuranceQuestionOpen && cartOpen ? 'translate-x-0' : 'translate-x-full'
                } flex flex-col`}>
                {user && (
                    <CartInsuranceQuestion
                        backClickHandler={() => {
                            setCartInsuranceQuestionOpen(false);
                        }}
                        closeHandler={() => {
                            dispatch(toggleCart(false));
                            setCartInsuranceQuestionOpen(false);
                        }}
                    />
                )}
            </motion.div>
        </AnimatePresence>
    );
};

export default Cart;
