import { createSlice, createSelector } from '@reduxjs/toolkit';
import { memoize } from 'proxy-memoize';
import { api } from '../../app/services/auth';

const initialState = {
    catalogItems: [],
    catalogCategories: [],
    catalogOptions: [],
    cpapMachinesOptionID: null,
    selectedOptions: [],
    selectedProduct: null,
    selectedVariation: null,
    sleepStudySku: null,
    recommendedSku: null,
    maskType: 'full',
    hstDepositPrice: null,
};

const getRecommendations = (state) => {
    if (!state.recommendedSku || state.catalogItems.length === 0) return null;

    const targetProduct = state.catalogItems.find((item) =>
        item.itemData.variations.some((v) => v.itemVariationData.sku === state.recommendedSku),
    );

    if (!targetProduct) return null;

    const targetVariation = targetProduct?.itemData?.variations?.find(
        (v) => v.itemVariationData.sku === state.recommendedSku,
    );

    const result = {
        mask: null,
        product: targetProduct,
        variation: targetVariation,
        options: targetVariation?.itemVariationData?.itemOptionValues,
    };

    const targetProductMaskCategory = Object.values(targetProduct.customAttributeValues).find(
        (att) => att.name === 'Mask Category',
    );

    if (!targetProductMaskCategory) return result;

    switch (targetProductMaskCategory.stringValue) {
        case 'Full Face Mask':
            result.mask = 'full';
            break;

        case 'Nasal Mask':
            result.mask = 'nasal';
            break;

        case 'Nasal Pillow':
            result.mask = 'pillow';
            break;

        default:
            break;
    }

    return result;
};

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: initialState,
    reducers: {
        setSelectedProduct: (state, { payload: productId }) => {
            state.selectedProduct = state.catalogItems.find((item) => item.id === productId);
        },
        setSelectedVariation: (state, { payload }) => {
            state.selectedVariation = payload;
        },
        setSelectedOptions: (state, { payload }) => {
            state.selectedOptions = payload;
        },
        setMaskType: (state, { payload }) => {
            state.maskType = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.getSquareProducts.matchFulfilled, (state, { payload }) => {
                state.catalogItems = payload.catalogItems;
                state.catalogCategories = payload.catalogCategories;
                state.catalogOptions = payload.catalogOptions;

                state.sleepStudySku = payload.catalogItems.find((item) =>
                    Object.values(item.customAttributeValues)?.some(
                        (att) => att.stringValue === process.env.REACT_APP_SLEEP_STUDY_ATTR_NAME,
                    ),
                ).itemData?.variations[0]?.itemVariationData.sku;

                state.hstDepositPrice =
                    payload.catalogItems.find((item) =>
                        item.itemData.variations.find(
                            (variation) => variation.itemVariationData.sku === 'VISITDEPOSIT',
                        ),
                    )?.itemData.variations[0].itemVariationData.priceMoney.amount / 100;

                state.cpapMachinesOptionID = payload.catalogOptions.find(
                    (option) =>
                        option.itemOptionData.name ===
                        process.env.REACT_APP_CPAP_MACHINE_OPTION_NAME,
                ).id;

                const recommendations = getRecommendations(state);

                if (recommendations) {
                    state.maskType = recommendations.mask;
                    state.selectedProduct = recommendations.product;
                    state.selectedVariation = recommendations.variation;
                    state.selectedOptions = recommendations.options;
                }
            })
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(
                api.endpoints.getRecommendedCPAPBundles.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.length > 0) {
                        state.recommendedSku =
                            payload[0].recommendedVariation.itemVariationData.sku;

                        state.pendingMaskFitReview = false;

                        const recommendations = getRecommendations(state);

                        if (recommendations) {
                            state.maskType = recommendations.mask;
                            state.selectedProduct = recommendations.product;
                            state.selectedVariation = recommendations.variation;
                            state.selectedOptions = recommendations.options;
                        }
                    }
                },
            )
            .addMatcher(api.endpoints.getCurrentSupplies.matchFulfilled, (state, { payload }) => {
                state.currentSupplies = payload;
            });
    },
});

export const { setSelectedProduct, setSelectedVariation, setSelectedOptions, setMaskType } =
    catalogSlice.actions;

export default catalogSlice.reducer;

export const selectCatalogCPAPBundles = memoize((state) =>
    state.catalog.catalogItems.filter((item) => {
        const bundleCategory = state.catalog.catalogCategories.find(
            (cat) => cat.categoryData.name === process.env.REACT_APP_CPAP_BUNDLE_CATEGORY_NAME,
        );

        return item.itemData.categoryId === bundleCategory?.id;
    }),
);

export const adminItemsSelector = createSelector([(state) => state.catalog], (catalog) => {
    const addItemOptions = catalog.catalogCategories.map((category) => ({
        label: category.categoryData.name,
        items: catalog.catalogItems
            .filter((item) => item?.itemData.categoryId === category?.id)
            .map((item) => ({
                label: item?.itemData?.name,
                value: item,
                id: item.id,
                variations:
                    item?.itemData?.variations.length > 0
                        ? item?.itemData?.variations.map((variation) => ({
                              label: variation?.itemVariationData?.name,
                              value: variation,
                              sku: variation?.itemVariationData?.sku,
                          }))
                        : null,
            })),
        id: category.id,
    }));

    return addItemOptions;
});
