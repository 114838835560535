import { useSelector } from 'react-redux';
import { useApplyDiscountToCartMutation } from '../../app/services/cart';
import { DiscountForm } from '../Cart';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { formatSquarePrice } from '../../utils/constants';
import { useInsuranceContext } from '../Insurance/InsuranceContext';

function ItemizedCart() {
    const { usingInsurance, cpapInCart, orderItems, discount, discountCode, subtotal } =
        useSelector((state) => state.cart);

    const { insurance } = useInsuranceContext();

    const [applyDiscount] = useApplyDiscountToCartMutation();

    return (
        <div className="px-5 lg:px-[60px] sm:mt-6 space-y-4 text-center xl:mt-0 xl:flex-1 xl:p-4 xl:pl-10 xl:text-left">
            <div className={`mb-8 max-w-[400px] border border-gray-light bg-white opacity-100`}>
                {!usingInsurance &&
                    cpapInCart &&
                    insurance &&
                    insurance.payer?.dmeReferralId !== null && (
                        <div className="space-y-4 border-b border-gray-light p-4">
                            <p className="text-bold font-heading tracking-widest">
                                Take $150 off your CPAP purchase with code:{' '}
                                <span className="text-gem-green">150CPAP</span>.
                            </p>
                        </div>
                    )}
                <ul className="space-y-4 p-4">
                    {orderItems.map((p, idx) => (
                        <li key={`cart-product-${p.id}`} className="flex space-x-1">
                            <span className="text-sm text-gray-six">{p.quantity}x</span>
                            <span className="flex-1 font-heading text-xl leading-none text-left ">
                                {p.name}
                            </span>
                            <span className="text-right text-xs sm:text-sm font-bold text-gray-six">
                                {usingInsurance && p.sku !== 'VISITDEPOSIT' ? (
                                    <p className="max-w-[120px] xs:max-w-[160px] sm:max-w-[180px]">
                                        Submitted to Insurance
                                    </p>
                                ) : (
                                    '$' + formatSquarePrice(p.price)
                                )}
                            </span>
                        </li>
                    ))}
                </ul>

                {!usingInsurance && (
                    <div>
                        <ul className="space-y-2 p-4 border-t border-gray-light ">
                            <li
                                className="flex items-center justify-between text-sm"
                                key="discount-line-item">
                                <span className="text-gray-six">Discount:</span>
                                {discountCode && discount > 0 ? (
                                    <div className="flex items-center font-bold text-green-hover">
                                        <span>{'-$' + formatSquarePrice(discount)}</span>
                                        <button
                                            className="relative ml-2 aspect-1 w-5 rounded-full bg-red"
                                            onClick={(ev) => applyDiscount(null)}>
                                            <CloseIcon className="absolute-center scale-75 text-white" />
                                        </button>
                                    </div>
                                ) : (
                                    <p>---</p>
                                )}
                            </li>
                            <li className="flex items-center justify-between text-sm">
                                <span className="text-gray-six">Subtotal:</span>
                                <span className="font-bold">
                                    {'$' + formatSquarePrice(subtotal)}
                                </span>
                            </li>
                            <li className="flex items-center justify-between text-sm">
                                <span className="text-gray-six">Shipping:</span>
                                <span className="font-bold">FREE</span>
                            </li>
                            <li className="flex items-center justify-between text-sm">
                                <span className="text-gray-six">Taxes:</span>
                                <span className="font-bold">INCLUDED</span>
                            </li>{' '}
                            <li className="flex items-center justify-between text-xl">
                                <span>Total:</span>
                                <span className="font-bold">
                                    {'$' + formatSquarePrice(subtotal)}
                                </span>
                            </li>
                            <div className="my-4 px-4">
                                <DiscountForm key="discount-checkout-field" />
                            </div>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ItemizedCart;
