import { useNavigate } from 'react-router-dom';
import { useAddToCartMutation } from '../../app/services/cart';
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg';

function AppointmentSchedulingButton({ styling, label, sku }) {
    const navigate = useNavigate();
    const [addToCart] = useAddToCartMutation();

    return (
        <button
            className={` ${styling}`}
            onClick={() => {
                addToCart([
                    {
                        quantity: 1,
                        sku: sku,
                        single: true,
                    },
                ]);

                navigate('/dashboard/appointments/overview');
            }}>
            <span>{label ? label : 'Schedule an appointment'}</span>
            <CalendarIcon style={{ color: 'white', marginLeft: '0.75rem' }} />
        </button>
    );
}

export default AppointmentSchedulingButton;
