import { BsCheckLg } from 'react-icons/bs';

function Line({ width, height, extraStyling }) {
    return <div className={`${width} ${height} ${extraStyling} bg-[#6C757D]`} />;
}

function CompletedStepDot({ betweenStepLineHeight, betweenStepLineWidth, end }) {
    return (
        <>
            <span className="flex h-[33px] w-[33px] items-center justify-center rounded-full bg-green-400 text-white border-[#484646] rounded-[50%] border-[2px]">
                <BsCheckLg className="h-[14px] w-[14px]" />
            </span>
            {!end && <Line height={betweenStepLineHeight} width={betweenStepLineWidth} />}
        </>
    );
}

function CurrentStepDot({ betweenStepLineHeight, betweenStepLineWidth, end }) {
    return (
        <>
            <div className="h-[33px] w-[33px] border-[#484646] rounded-[50%] border-[2px]">
                <div className="relative top-[3%] left-[3%] w-[94%] h-[94%] border-white rounded-[50%] border-[2px]">
                    <div className="relative top-[3%] left-[3%] w-[94%] h-[94%] bg-transparent rounded-[50%]" />
                </div>
            </div>
            {!end && <Line height={betweenStepLineHeight} width={betweenStepLineWidth} />}
        </>
    );
}

function OtherDots({ betweenStepLineHeight, betweenStepLineWidth, end }) {
    return (
        <>
            <div className="h-[33px] w-[33px] flex flex-col xl:flex-row items-center">
                <Line height="h-[8px] xl:h-[1px]" width="w-[1px] xl:w-[8px]" />
                <span className="h-[17px] w-[17px] border-[#484646] bg-[#A3ACB5] rounded-[50%] border-[2px]" />
                {!end && <Line height="h-[8px] xl:h-[1px]" width="w-[1px] xl:w-[8px]" />}
            </div>
            {!end && <Line height={betweenStepLineHeight} width={betweenStepLineWidth} />}
        </>
    );
}

function StepLabel({ text, centered, textColor }) {
    return (
        <div className="h-[66px] w-[90px] xl:w-[96px] flex items-center">
            <p
                className={`text-center font-heading text-[22px] ${textColor} ${
                    centered ? 'xl:text-center' : 'xl:text-left xl:pl-6'
                }`}>
                {text}
            </p>
        </div>
    );
}

export function GemSleepPathLine({ leftTrailing, pathLabels, stepsCompleted }) {
    const steps = [];

    //Start at one to skip first completed step
    for (let i = 0; i < pathLabels.length; i++) {
        if (i < stepsCompleted) steps.push({ completed: true, current: false });
        else if (i === stepsCompleted) steps.push({ completed: false, current: true });
        else steps.push({ completed: false, current: false });
    }

    const betweenStepLineHeight =
        steps.length === 5 ? 'h-[82px] xl:h-[1px]' : 'h-[130px] xl:h-[1px]';
    const betweenStepLineWidth =
        steps.length === 5 ? 'w-[1px] xl:w-[202px]' : 'w-[1px] xl:w-[220px]';

    return (
        <div className="flex mt-6 xl:mt-0 xl:block">
            <div className="basis-1/2 flex flex-col items-center pt-5 pb-5 xl:pl-10 xl:pt-20 xl:flex-row ">
                {leftTrailing && (
                    <Line
                        extraStyling={`bg-gradient-to-b xl:bg-gradient-to-r from-black`}
                        height="h-[60px] xl:h-[1px]"
                        width="w-[1px] xl:w-[60px] xl:w-[60px]"
                    />
                )}
                {steps.map((step, idx) =>
                    step.completed ? (
                        <CompletedStepDot
                            end={idx === steps.length - 1}
                            betweenStepLineHeight={betweenStepLineHeight}
                            betweenStepLineWidth={betweenStepLineWidth}
                            key={`${idx}-dot`}
                        />
                    ) : step.current ? (
                        <CurrentStepDot
                            end={idx === steps.length - 1}
                            betweenStepLineHeight={betweenStepLineHeight}
                            betweenStepLineWidth={betweenStepLineWidth}
                            key={`${idx}-dot`}
                        />
                    ) : (
                        <OtherDots
                            end={idx === steps.length - 1}
                            betweenStepLineHeight={betweenStepLineHeight}
                            betweenStepLineWidth={betweenStepLineWidth}
                            key={`${idx}-dot`}
                        />
                    ),
                )}
            </div>
            <div
                className={`basis-1/2 w-full flex flex-col mb-10 xl:flex-row xl:mb-20
                    ${leftTrailing ? 'mt-[60px] xl:mt-0 ml-0 xl:ml-[70px]' : 'mt-2 xl:mt-0 ml-0 xl:ml-3'} 
                    ${
                        steps.length === 5
                            ? 'gap-[47px] xl:gap-[139px]'
                            : 'gap-[97px] xl:gap-[156px]'
                    } `}>
                {pathLabels.map((label, idx) => (
                    <StepLabel
                        key={`${idx}-label`}
                        centered={label.centered}
                        text={label.text}
                        textColor={steps[idx].current ? 'text-white' : ''}
                    />
                ))}
            </div>
        </div>
    );
}

function GemSleepPath({
    bgOverride,
    footer,
    descriptionText,
    leftTrailing,
    pathLabels,
    stepsCompleted,
}) {
    return (
        <div
            className={`xl:block ${bgOverride ? bgOverride : 'bg-gray-darker'} mt-4 text-gray-light w-full`}>
            <div className="p-12 xl:p-18 xl:ml-6">
                <h5 className="text-center xl:text-left text-white font-heading text-5xl mb-2">
                    The path to better sleep
                </h5>
                {descriptionText ? (
                    <p className="text-center xl:text-left">{descriptionText}</p>
                ) : (
                    <p className="text-center xl:text-left"></p>
                )}
                <GemSleepPathLine
                    leftTrailing={leftTrailing}
                    pathLabels={pathLabels}
                    stepsCompleted={stepsCompleted}
                />
                {footer}
            </div>
        </div>
    );
}

export default GemSleepPath;
