import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';

const initialState = {
    hstInCart: false,
    hasOrderedHST: false,
    hasOrderedCPAP: false,
    orderHistory: null,
};

export const ordersSlice = createSlice({
    name: 'catalog',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getOrderHistory.matchFulfilled, (state, { payload }) => {
                if (!payload) return;

                state.orderHistory = payload;
                state.hasOrderedHST = payload.some(
                    (order) =>
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'SLEEP_STUDY'),
                );
                state.hasOrderedCPAP = payload.some(
                    (order) =>
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'CPAP_SUPPLIES'),
                );
            });
    },
});

export default ordersSlice.reducer;
