import { Navigate, useNavigate } from 'react-router-dom';
import { useCheckoutContext } from '../../components/Checkout/CheckoutContext';
import { GemSleepPath } from '../../components/Diagnosis';
import Telehealth from '../../images/telehealth.png';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useInsuranceContext } from '../../components/Insurance';

const Overview = () => {
    const navigate = useNavigate();
    const { isConsultOrder, orderItems } = useSelector((state) => state.cart);
    //Determine if the order is DIACLINICAL, otherwise assume that it is COMPLIANCE
    const isDiaclinicalConsult = orderItems.some((item) => item.sku === 'DIACLINICAL');

    const { setCheckoutStep } = useCheckoutContext();
    const { hasInsuranceAlternative, insurance } = useInsuranceContext();

    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (isConsultOrder) {
            setReady(true);
        }
    }, [isConsultOrder]);

    const pathLabels = isDiaclinicalConsult
        ? [
              { text: 'Sleep Study', centered: true },
              { text: 'Complete Diagnosis Visit', centered: true },
              { text: 'The right treatment', centered: true },
              { text: 'Personalized support', centered: true },
          ]
        : [
              { text: 'Sleep Study', centered: true },
              { text: 'The right treatment', centered: false },
              { text: 'Personalized Support', centered: true },
              { text: 'CPAP Compliance Visit', centered: true },
          ];

    return !ready ? (
        <div className="layout-outlet-root">
            <p className="font-heading tracking-widest mt-4 text-xl">Loading...</p>
        </div>
    ) : !isConsultOrder ? (
        // Return to home if you remove the consult from cart
        <Navigate to="/dashboard" />
    ) : (
        <>
            <div className="layout-outlet-root mt-5">
                <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mt-4">
                    <div className="w-full lg:w-1/2 lg:mr-5">
                        <h5 className="font-heading text-4xl font-bold lg:text-5xl">
                            {isDiaclinicalConsult
                                ? 'Diagnosis Appointment'
                                : 'Compliance Appointment'}
                        </h5>
                        {isDiaclinicalConsult ? (
                            <p className="mt-2">
                                If you would like to discuss the results of your sleep study with
                                your care provider, you may schedule a virtual visit. Your care
                                provider can answer questions about the results shown in your sleep
                                report and potential therapy options.
                            </p>
                        ) : (
                            <div className="mt-2">
                                <p>
                                    Now that you are on therapy, your health insurance plan requires
                                    you meet with your care provider for a follow-up visit. During
                                    this visit, your provider will:
                                </p>
                                <ul className="list-disc list-inside">
                                    <li>Review your CPAP data</li>
                                    <li>Look at your AHI improvement</li>
                                    <li>Answer questions or concerns you may have.</li>
                                </ul>
                                <p>
                                    If you do not complete this visit within 90 days of starting
                                    treatment, your health insurance plan may deny paying for
                                    further installments on your therapy or for future therapy
                                    supplies.
                                </p>
                            </div>
                        )}
                        <button
                            className="btn-primary w-full mt-4"
                            onClick={() => {
                                setCheckoutStep(
                                    insurance
                                        ? hasInsuranceAlternative
                                            ? 'insurance-alternative-check'
                                            : 'insurance-review'
                                        : 'insurance-details',
                                );
                                navigate('/dashboard/checkout');
                            }}>
                            CONTINUE
                        </button>
                    </div>
                    <div>
                        <img
                            className="lg:w-[300px] hidden lg:block"
                            alt="clinical consult"
                            src={Telehealth}
                        />
                    </div>
                </div>
            </div>

            <GemSleepPath
                bgOverride="bg-[#2E2E2E]"
                leftTrailing={true}
                pathLabels={pathLabels}
                stepsCompleted={isDiaclinicalConsult ? 1 : 3}
            />
        </>
    );
};
export default Overview;
