import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { routes } from '../../utils/routes';

const BreadcrumbsDisplay = () => {
    const breadcrumbs = useBreadcrumbs(routes, {
        excludePaths: [
            '/',
            '/dashboard',
            '/dashboard/home',
            '/dashboard/resources',
            '/dashboard/treatment/bundle',
        ],
    });
    return (
        <div className="pl-[60px] h-[60px] lg:h-[80px] flex divide-x-2 divide-gray-six items-center max-w-[calc(100%-100px)]">
            {breadcrumbs.length > 1 &&
                breadcrumbs.map(({ match, breadcrumb }, idx) => (
                    <NavLink
                        to={
                            match.pathname === '/dashboard/resources/blog'
                                ? '/dashboard/resources/'
                                : match.pathname
                        }
                        key={match.pathname}
                        className={`leading-none font-heading text-base font-bold uppercase tracking-widest ${
                            idx > 0 && 'whitespace-nowrap overflow-hidden text-ellipsis'
                        }
                    ${idx === breadcrumbs.length - 1 ? '' : 'text-gray-six'} 
                    ${idx > 0 ? 'px-4' : 'pr-4'}`}>
                        {breadcrumb}
                    </NavLink>
                ))}
        </div>
    );
};

export default BreadcrumbsDisplay;
