import Select from 'react-select';
// import { FaCheck } from 'react-icons/fa';
import { adminSelectCustomStyles } from '../../utils/constants';

function DropdownColumnSelection({ options, columns, setColumns }) {
    function handleSelectChange(selectedOption) {
        const selectedValue =
            selectedOption?.length === 0
                ? []
                : selectedOption?.length === 1
                ? [selectedOption[0].value]
                : selectedOption.map((option) =>
                      typeof option === 'object' ? option.value : option
                  );

        setColumns(selectedValue);
    }

    const selectOptions = options.map((option) => ({ label: option, value: option }));

    return (
        <Select
            closeMenuOnSelect={false}
            options={selectOptions}
            className="my-4"
            onChange={handleSelectChange}
            isMulti
            placeholder={'Select columns to display'}
            styles={adminSelectCustomStyles}
            value={selectOptions.filter((option) => columns.includes(option.value))}
        />
    );
}

export default DropdownColumnSelection;
