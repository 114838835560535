import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    setStatusFilter,
    setAvailableFilters,
    setFilterComponents,
} from '../../../features/admin/adminSlice';
import {
    useGetPartnerOrganizationsQuery,
    useGetPayersQuery,
} from '../../../app/services/insurance';
import { adminSelectCustomStyles, orderStatusesWithOptions } from '../../../utils/constants';
import { useGetCoachesQuery } from '../../../app/services/admin/careteam';

import OrderFilter from './OrderFilter';
import { NameEmailIDSearch } from '../PatientSearchQueries';

const OrderFilters = ({ setPage }) => {
    const dispatch = useDispatch();
    const {
        availableOrderFilters,
        careTeamOptions,
        orderFilterComponents,
        activeOrderFilters,
        payerOptions,
        partnersOptions,
    } = useSelector((state) => state.admin);

    //Get list of partners, payers, and careteam users
    const { isLoading: partnersLoading } = useGetPartnerOrganizationsQuery({ paginated: false });
    const { isLoading: careTeamLoading } = useGetCoachesQuery();
    const { isLoading: payersLoading } = useGetPayersQuery();

    // Once isLoading is resolved, this will add partners and payers to the available filters
    useEffect(() => {
        if (!partnersLoading && !payersLoading && !careTeamLoading) loadAdditionalFilters();
        // eslint-disable-next-line
    }, [partnersLoading, payersLoading, careTeamLoading]);

    //Iterate ID of each filter to have unique keys for removal
    const [filterId, setFilterId] = useState(1);

    //List of filters which are used for orders
    const filterOptions = {
        filterTypes: [
            'appointmentStatus',
            'appointmentType',
            'careTeam',
            'cpapOrderStatus',
            'estimateStatus',
            'homeSleepStudyStatus',
            'insurance',
            'insuranceStatus',
            'orderStatus',
            'orderType',
            'priorAuthStatus',
            'referFromOrganization',
        ],
        availableFilterKey: 'availableOrderFilters',
        adminFilterKey: 'activeOrderFilters',
        statusFilterKey: 'orderFilterComponents',
    };

    const orderItemOptions = [
        { value: 'CPAP_SUPPLIES', label: 'CPAP' },
        { value: 'SLEEP_STUDY', label: 'HST' },
        { value: 'CLINICAL_CONSULTS', label: 'Consult' },
        { value: 'SUPPLIES', label: 'Supplies' },
        { value: 'DEPOSIT', label: 'Deposit' },
        { value: 'OTHER', label: 'Other' },
    ];

    const otherSearchOptions = ['careTeam', 'insurance', 'appointmentStatus', 'appointmentType'];

    function loadAdditionalFilters() {
        dispatch(
            setAvailableFilters({
                key: 'availableOrderFilters',
                value: [
                    ...orderStatusesWithOptions,
                    {
                        available: true,
                        value: 'insurance',
                        label: 'Insurance',
                        statusOptions: payerOptions,
                    },
                    {
                        available: true,
                        value: 'careTeam',
                        label: 'Care Team',
                        statusOptions: careTeamOptions,
                    },
                    {
                        available: true,
                        value: 'referFromOrganization',
                        label: 'Refer From Organization',
                        statusOptions: partnersOptions,
                    },
                ],
            }),
        );
    }

    function removeFilter(id) {
        //Find the filter to deleted
        const filterToDelete = orderFilterComponents.filter((item, index) => item.filterId === id);

        //If it exists, proceed with deleting it
        if (filterToDelete[0].status.value) {
            setPage(0);
            const newAvailableFilters = availableOrderFilters.filter((filter) => {
                if (filter !== filterToDelete[0].status) {
                    return filter;
                } else return null;
            });
            newAvailableFilters.push({ ...filterToDelete[0].status, available: true });

            dispatch(
                setStatusFilter({
                    type: filterOptions.adminFilterKey,
                    key: filterToDelete[0].status.value,
                    value: null,
                }),
            );

            //Set available filters to include the filter which was deleted
            dispatch(
                setAvailableFilters({
                    key: filterOptions.availableFilterKey,
                    value: newAvailableFilters,
                }),
            );
        }

        //Create an array without the filter needing removal and set state to this new array
        const newStatusFilters = orderFilterComponents.filter((item) => item.filterId !== id);
        dispatch(
            setFilterComponents({ key: filterOptions.statusFilterKey, value: newStatusFilters }),
        );
    }

    //Add the filter with an associated id from the state value, increment the state value so each has a unique id
    function addFilter() {
        const newFilters = [
            ...orderFilterComponents,
            {
                filterId: filterId,
                status: {},
                previousStatus: undefined,
                filters: [],
            },
        ];

        dispatch(setFilterComponents({ key: filterOptions.statusFilterKey, value: newFilters }));
        setFilterId(filterId + 1);
    }

    //Clears all patient filters and removes them from their array, resets available filters
    function clearFilters() {
        //Remove any admin filters that were set from the filters
        dispatch(
            setStatusFilter(
                filterOptions.filterTypes.map((filterType) => ({
                    type: filterOptions.adminFilterKey,
                    key: filterType,
                    value: null,
                })),
            ),
        );
        //Set all status filters to be available
        loadAdditionalFilters();

        //Remove all status filter components
        dispatch(setFilterComponents({ key: filterOptions.statusFilterKey, value: [] }));
        setPage(0);
    }

    return (
        <>
            <div className="mb-4">
                <div className="flex items-center space-x-4 mb-2">
                    <label className="w-[50px]">Date:</label>
                    <DatePicker
                        isClearable
                        className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[130px]"
                        selected={
                            activeOrderFilters.startDate
                                ? new Date(activeOrderFilters.startDate)
                                : null
                        }
                        onChange={(date) => {
                            setPage(0);
                            dispatch(
                                setStatusFilter({
                                    type: 'activeOrderFilters',
                                    key: 'startDate',
                                    value: date ? new Date(date).toISOString() : null,
                                }),
                            );
                        }}
                    />

                    {activeOrderFilters.startDate && (
                        <>
                            <label className="w-[50px]">Until:</label>
                            <DatePicker
                                isClearable
                                className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[130px]"
                                selected={
                                    activeOrderFilters.endDate
                                        ? new Date(activeOrderFilters.endDate)
                                        : null
                                }
                                onChange={(date) => {
                                    setPage(0);
                                    dispatch(
                                        setStatusFilter({
                                            type: 'activeOrderFilters',
                                            key: 'endDate',
                                            value: date ? new Date(date).toISOString() : null,
                                        }),
                                    );
                                }}
                            />
                        </>
                    )}

                    <label className="w-[50px]">Item:</label>
                    <Select
                        isClearable
                        options={orderItemOptions}
                        className="w-[185px]"
                        onChange={(newValue) => {
                            setPage(0);
                            dispatch(
                                setStatusFilter({
                                    type: 'activeOrderFilters',
                                    key: 'orderItemType',
                                    value: newValue ? newValue : null,
                                }),
                            );
                        }}
                        placeholder={'Select Item'}
                        styles={adminSelectCustomStyles}
                        value={activeOrderFilters.orderItemType}
                    />
                </div>

                <div className="flex items-center space-x-4 mb-2">
                    <label className="w-[50px]">Patient:</label>
                    <NameEmailIDSearch setPage={setPage} activeFilters="activeOrderFilters" />
                </div>

                <div className="w-full flex flex-wrap items-center">
                    {orderFilterComponents?.map((obj, index) => (
                        <div
                            className="basis-full lg:basis-1/2 flex flex-wrap items-center md:mb-2"
                            key={obj.filterId + 'div'}>
                            <OrderFilter
                                setPage={setPage}
                                key={obj.filterId + 'filter'}
                                id={obj.filterId}
                                index={index}
                                status={obj.status}
                                previousStatus={obj.previousStatus}
                                filters={obj.filters}
                                orderFilterComponents={orderFilterComponents}
                                otherSearchOptions={otherSearchOptions}
                                setFilterComponents={setFilterComponents}
                                setAvailableFilters={setAvailableFilters}
                            />
                            <button
                                className="ml-2 md:ml-0 md:basis-[6%] text-red font-bold"
                                key={obj.filterId + 'remove button'}
                                onClick={() => {
                                    removeFilter(obj.filterId);
                                }}>
                                X
                            </button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap items-center space-x-4">
                    {
                        //Do not show more filters than can be set
                        orderFilterComponents.length < availableOrderFilters.length ? (
                            <button
                                onClick={addFilter}
                                className="btn-primary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                Add filter
                            </button>
                        ) : null
                    }
                    {
                        //Only show when a filter has been added
                        orderFilterComponents.length > 0 ? (
                            <>
                                <button
                                    onClick={() => clearFilters()}
                                    className="btn-secondary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                    Clear Filters
                                </button>
                            </>
                        ) : null
                    }
                </div>
            </div>
        </>
    );
};

export default OrderFilters;
